<template>
  <div class="login">
    <!-- Elementos ocultos para precargar las imágenes -->
    <img src="../assets/img/background.jpg" style="display: none;">
    <div class="login-container">
      <p class="login-title">Selecciona con que cuenta ingresar</p>
      <div class="seleccionarCuenta-reemplazos" v-if="$usuario && $usuario.id != null">
        <div class="seleccionarCuenta-reemplazo" @click="selectedOption = $usuario.id">
          <img
            class="seleccionarCuenta-img"
            v-if="$usuario.imagen != null"
            v-bind:src="$localurl + '/' + $usuario.imagen"
          />
          <div class="seleccionarCuenta-letra" v-else-if="$usuario.nombre">
            <label :style="{color: colors[0].color, backgroundColor: colors[0].backgroundColor}">{{$usuario.nombre.substr(0,1)}}</label>
          </div>
          <div class="seleccionarCuenta-nombre-container">
            <label class="seleccionarCuenta-nombre">{{$usuario.nombre}}</label>
            <label class="seleccionarCuenta-email">{{$usuario.email}}</label>
          </div>
          <label class="seleccionarCuenta-check">
            <input type="radio" :id="$usuario.id" :value="$usuario.id" v-model="selectedOption">
            <div>
              <img v-if="selectedOption === $usuario.id" src="../assets/img/icons/check2.png" />
            </div>
          </label>
        </div>
        <div v-for="(reemplazo, index) in reemplazos" :key="reemplazo.id" class="seleccionarCuenta-reemplazo" @click="selectedOption = reemplazo.reemplazado.id">
          <img
            class="seleccionarCuenta-img"
            v-if="reemplazo.reemplazado.imagen != null"
            v-bind:src="$localurl + '/' + reemplazo.reemplazado.imagen"
          />
          <div class="seleccionarCuenta-letra"  v-else-if="reemplazo.reemplazado.nombre">
            <label :style="{color: colors[index+1].color, backgroundColor: colors[index+1].backgroundColor}">{{reemplazo.reemplazado.nombre.substr(0,1)}}</label>
          </div>
          <div class="seleccionarCuenta-nombre-container">
            <label class="seleccionarCuenta-nombre">{{reemplazo.reemplazado.nombre}}</label>
            <label class="seleccionarCuenta-email">{{reemplazo.reemplazado.email}}</label>
          </div>
          <label class="seleccionarCuenta-check">
            <input type="radio" :id="reemplazo.reemplazado.id" :value="reemplazo.reemplazado.id" v-model="selectedOption">
            <div>
              <img v-if="selectedOption === reemplazo.reemplazado.id" src="../assets/img/icons/check2.png" />
            </div>
          </label>
        </div>
      </div>
      <div class="seleccionarCuenta-btns">
        <button class="seleccionarCuenta-btn seleccionarCuenta-btn-salir" @click="logout()">Salir</button>
        <button v-if="selectedOption == null" class="seleccionarCuenta-btn seleccionarCuenta-btn-ingresar" style="opacity: 0.5">Ingresar ahora</button>
        <button v-else class="seleccionarCuenta-btn seleccionarCuenta-btn-ingresar" @click="ingresar()">Ingresar ahora</button>
      </div>
    </div>
    <div class="login-right">
      <div class="login-logo-container">
        <img class="login-logo" src="../assets/img/logo.svg" />
      </div>
      <div v-show="$dev == true" class="dev-container">
        <p>Entorno de desarrollo</p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "seleccionarCuenta",
  components: {},
  data() {
    return {
      reemplazos: [],
      selectedOption: null,
      colors: [
        {
          color: "rgb(1 53 113)",
          backgroundColor: "rgb(1 53 113 / 30%)"
        },
        {
          color: "rgb(153 0 14)",
          backgroundColor: "rgb(153 0 14 / 30%)"
        },
        {
          color: "rgb(1 113 16)",
          backgroundColor: "rgb(1 113 16 / 30%)"
        },
        {
          color: "rgb(113 111 1)",
          backgroundColor: "rgb(113 111 1 / 30%)"
        },
        {
          color: "rgb(97 1 113",
          backgroundColor: "rgb(97 1 113 / 30%)"
        }
      ]
    };
  },
  created() {
  },
  mounted() {
    this.$eventHub.$on("update", () => this.$forceUpdate());
    
    if (this.$store.getters.reemplazos != null) {
      this.reemplazos = this.$store.getters.reemplazos;
    }

    const id = localStorage.getItem("reemplazo-id");

    if(id == "" || id == null){
      this.getReemplazos();
    }else{
      Vue.prototype.$usuario = {
        rol: {},
        rolClientes: {},
      };
      const that = this;
      this.$axios
        .post(this.$localurl + "/api/usuario/volver/" + id)
        .then(async function (response) {
          console.log(response);
          localStorage.setItem("token-landing", response.data);
          that.$eventHub.$emit("loged");
  
          that.getReemplazos();
        })
        .catch(function (e) {
          console.log(e);
          that.ingresando = false;
          that.errorUsuario = true;
          that.errorPassword = true;
        });
    }

  },
  methods: {
    getReemplazos() {
      const id = localStorage.getItem("user-id");

      let that = this;
      this.$axios
        .get(this.$localurl + "/api/reemplazos/" + id)
        .then(function (response) {
          const reemplazos = response.data;

          that.$store.commit("setReemplazos", reemplazos);
          that.reemplazos = reemplazos;
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    logout() {
      this.$authService.logout();
    },
    ingresar(){
      localStorage.removeItem("token-landing");
      this.$store.commit("setReemplazante", null);
      localStorage.removeItem("reemplazo-id");

      const that = this;
      const params = {
        id: this.selectedOption
      }
      const id = localStorage.getItem("user-id");

      that.$axios
        .post(that.$localurl + "/api/usuario/reemplazar/" + id, params)
        .then(function (response) {
          localStorage.setItem("token-landing", response.data.token);
          localStorage.setItem("reemplazo-id", response.data.reemplazo_id);
          that.$store.commit("setReemplazante", response.data.reemplazado != "" ? JSON.parse(response.data.reemplazado) : null);
          that.$eventHub.$emit("loged");
          that.$router.push("/");
        })
        .catch(function (error) {
          // handle error
          alert(error);
        });
    }
  },
};
</script>

<style>
@import "../assets/css/views/login.css";
</style>
<style>
@import "../assets/css/views/seleccionarCuenta.css";
</style>
